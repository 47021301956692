<template>
  <section>
    <vx-card class="mb-8" title="General">
      <div class="mb-6">
        <vs-input v-model="form.title" class="w-full" label="Titlul" placeholder="Titlul Paginii"/>
        <error-messages :errors="errors.title"></error-messages>
      </div>
      <page-body v-model="form.body" class="mb-6">
        <error-messages :errors="errors.body"/>
      </page-body>
    </vx-card>
    <vx-card class="mb-8" title="SEO">
      <div class="mb-6">
        <vs-input v-model="form.meta_title" class="w-full" label="Titlul Meta" placeholder="Titlul Meta"/>
        <error-messages :errors="errors.meta_title"></error-messages>
      </div>
      <div class="mb-6">
        <vs-input v-model="form.meta_description" class="w-full" label="Descriere Meta" placeholder="Descrierea meta"/>
        <error-messages :errors="errors.meta_description"></error-messages>
      </div>
    </vx-card>
    <vx-card class="mb-8" title="Actiuni">
      <vs-button class="mr-2 primary-button" color="primary"  type="filled" @click="savePage">Salveaza</vs-button>
    </vx-card>

  </section>
</template>

<script>
import PageBody from '@/views/PageManagement/components/PageBody'
import ErrorMessages from '@/components/general-form/ErrorMessages'

export default {
  name: 'AddPage',
  components: {ErrorMessages, PageBody},
  data () {
    return {
      form: {
        body: '',
        title: '',
        meta_title: '',
        meta_description: ''
      },
      errors: {}
    }
  },
  methods: {
    savePage () {
      this.errors = {}
      this.$http.post('/pages', this.form)
        .then(() => {
          this.$router.push({name: 'settings-page-management'})
        })
        .catch(({response}) => {
          if (response.status === 422) {
            this.errors = response.data.errors
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Au fost gasite erori de validare pentru unul sau mai multe campuri!',
              'color': 'danger'
            })
          } else {
            this.$vs.notify({
              'title': 'Eroare',
              'text': 'Eroare la salvare, va rugam reimprospatati pagina sau incercati mai tarziu',
              'color': 'danger'
            })
          }
        })
    }
  }
}
</script>
