<template>
  <div>
    <editor
      :init="myInit"
      :plugins="myPlugins"
      :toolbar="myToolbar1"
      :api-key="apiKey"
      v-model="text"

    />
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
    name: 'Tinymce',
    components: {
      'editor': Editor
    },
    props: {
      value: {},
      apiKey: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        myToolbar1: 'undo redo | bold italic underline forecolor backcolor | alignleft aligncenter alignright alignjustify | hr bullist numlist outdent indent | link image table | code preview',
        myPlugins: 'link image code preview imagetools table lists textcolor hr wordcount',
        myInit: {
          // eslint-disable-next-line no-unused-vars
          images_dataimg_filter(img) {
            return false
            // return img.hasAttribute('internal-blob')
          },
          convert_urls: false,
          height: 300,
          automatic_uploads: false,
          images_upload_base_path: '/../../',
          relative_urls: false,
          // paste_as_text: true,
          // plugins: "paste",
          style_formats: [
            {
              title: 'Image Left',
              selector: 'img',
              styles: {
                'float': 'left',
                'margin': '0px 10px 10px 0'
              }
            },
            {
              title: 'Image Right',
              selector: 'img',
              styles: {
                'float': 'right',
                'margin': '0 0 10px 10px'
              }
            }
          ],

          // override default upload handler to simulate successful upload
          // eslint-disable-next-line no-unused-vars
          images_upload_handler(blobInfo, success, failure, folderName) {
            const xhr = new XMLHttpRequest();
            xhr.withCredentials = false;

            xhr.open('POST', '/api/upload-image');
            const token = document.head.querySelector('[name=csrf-token]').content;
            xhr.setRequestHeader('X-CSRF-Token', token);

            xhr.onload = function () {

              // eslint-disable-next-line eqeqeq
              if (xhr.status != 200) {
                failure(`HTTP Error: ${xhr.status}`);
                return
              }
              const json = JSON.parse(xhr.responseText);

              if (!json || typeof json.location !== 'string') {
                failure(`Invalid JSON: ${xhr.responseText}`);
                return
              }
              success(json.location)

            };

            const formData = new FormData();
            formData.append('file', blobInfo.blob(), blobInfo.filename());

            xhr.send(formData)

          }
        }
      }
    },
    computed: {
      text: {
        get() {
          return this.value
        },
        set(newValue) {
          this.$emit('input', newValue)
        }
      }
    },
    methods: {}
  }
</script>

<!--suppress CssUnusedSymbol -->
<style>
  .ck-editor__editable_inline {
    min-height: 400px;
  }

  .ck-rounded-corners .ck.ck-balloon-panel,
  .ck.ck-balloon-panel.ck-rounded-corners {
    z-index: 9999000;
  }
</style>
